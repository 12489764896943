import request from '../utils/request'
import {IShareData, IWxConfig, IWxInstance, JSAPI_ACCESS_LIST, OPENTAGLIST} from './wx-constrains'
import Session from '../session'
import Variable from '../utils/variable'
import { payPlatform } from '../utils/platform'

const GlobalVariable = new Variable().GlobalVariable

interface IKeyTime {
    wxBeforeGetConfig: null | Number
    wxGetConfig: null | Number
}

const WeixinConfig = {
        WX_APP_ID: GlobalVariable.WX_APP_ID,
        API_BASE_URL: GlobalVariable.API_BASE_URL,
        JSSDK_CONFIG_API: GlobalVariable.JSSDK_CONFIG_API
}

const Config = WeixinConfig

class WeixinApi {
    private static getDefaultShareData(): IShareData {
        return {
            title: '荔枝微课，拥有PPT语音同步、视频语音交互，一分钟创建课程。与三千万伙伴一同享受成长的快乐。只有一种稳赚不赔的投资，就是学习',
            desc: '荔枝微课，拥有PPT语音同步、视频语音交互，一分钟创建课程。与三千万伙伴一同享受成长的快乐。只有一种稳赚不赔的投资，就是学习',
            // tslint:disable-next-line
            imgUrl: 'https://static-1253442168.image.myqcloud.com/picture/8173011_2c2209ae7abd04b1a62a65d75ce54a2f.jpg-picmsg420',
            link: location.href
        }
    }

    public readonly wx: IWxInstance
    private readonly session: Session
    private wxConfig: IWxConfig | null = null
    public readonly keyTime: IKeyTime = { // 一些关键时间点
        wxBeforeGetConfig: null, // 准备获取微信config之前的时间
        wxGetConfig: null, // 得到微信config的时间
    }
    public readonly status = {
        wxReady: false,
        wxBridgeReady: false
    }

    constructor(wx: IWxInstance, session: Session) {
        this.wx = wx
        this.session = session

        // 初始化状态
        wx.ready(() => {
            this.status.wxReady = true
            this.status.wxBridgeReady = true
        })

        // 初始化 bridge
        if ((window as any).WeixinJSBridge) {
            this.status.wxBridgeReady = true
            this._runReadyQueue()
        } else {
            document.addEventListener('WeixinJSBridgeReady', () => {
                this.status.wxBridgeReady = true
                this._runReadyQueue()
            }, false)
        }
        wx.bridgeReady = (cb) => { this.bridgeReady(cb) } 
        
        // 如果是在微信平台再获取微信配置
        if(payPlatform === "weixin") {
            this.initJSSDK()
        }
 
    }

    private bridgeReadyQueue: (() => any)[] = []
    private _runReadyQueue() {
        this.bridgeReadyQueue.forEach(cb => cb())
        this.bridgeReadyQueue = []
    }

    public bridgeReady(callback: () => any) {
        if (this.status.wxBridgeReady) {
            callback()
        } else {
            this.bridgeReadyQueue.push(callback)
        }
    }

    public wxShare(
        data: IShareData | [IShareData, IShareData],
        successFunc?: (shareType: 'appMessage' | 'timeline') => any) {
        this.wx.ready(() => {
            const defaultShareData = WeixinApi.getDefaultShareData()

            let appShareData: IShareData
            let timeLineShareData: IShareData

            if (Array.isArray(data)) {
                appShareData = data[0]
                timeLineShareData = data[1]
            } else {
                appShareData = data
                timeLineShareData = data
            }

            const appMessageSuccess = () => {
                if (successFunc) {
                    successFunc('appMessage')
                }
            }

            const timelineSuccess = () => {
                if (successFunc) {
                    successFunc('timeline')
                }
            }

            // 微信文档中提到这两个接口即将弃用，故判断
            if (this.wx.updateAppMessageShareData) {
                // 1.4 分享到朋友和朋友圈
                this.wx.updateAppMessageShareData({...defaultShareData, ...appShareData, success: appMessageSuccess})
                this.wx.updateTimelineShareData({...defaultShareData, ...timeLineShareData, success: timelineSuccess})
            } else {
                // 1.0 分享到朋友和朋友圈
                this.wx.onMenuShareAppMessage({...defaultShareData, ...appShareData, success: appMessageSuccess})
                this.wx.onMenuShareTimeline({...defaultShareData, ...timeLineShareData, success: timelineSuccess})
            }
        })
    }

    private initJSSDK(wxAppId: string = Config.WX_APP_ID) {
        this.keyTime.wxBeforeGetConfig = Date.now()
        this.session.getToken(({token}) => {
            console.log('url:', location.href)
            request({
                method: 'GET',
                baseUrl: Config.API_BASE_URL,
                url: Config.JSSDK_CONFIG_API,
                query: {
                    url: location.href,
                    appid: Config.WX_APP_ID,
                    token
                }
            }, (wxConfig: any) => {
                this.wxConfig = wxConfig as IWxConfig
                this.wxConfig.appId = wxAppId

                this.wxConfig.timestamp = wxConfig.data.timestamp
                this.wxConfig.nonceStr = wxConfig.data.nonceStr
                this.wxConfig.signature = wxConfig.data.signature

                this.wxConfig.jsApiList = JSAPI_ACCESS_LIST
                this.wxConfig.openTagList = OPENTAGLIST
                this.wxConfig.debug = GlobalVariable.NODE_ENV !== 'production'
                this.keyTime.wxGetConfig = Date.now()
                this.wx.config(this.wxConfig)
            })
        })
    }
}

export default WeixinApi
