import Session from './session'
import WeixinApi from './weixin/weixin-api'
import PayManager from './pay/pay-manager'
import {payPlatform, PayPlatformType} from './utils/platform'
import {wx} from './weixin/wx-constrains'

export interface IExports extends Session {
    weixinApi?: WeixinApi
    payManager: PayManager
    payPlatform: PayPlatformType
}

let modules: IExports | null = null

if (!(window as any).sessionDisable) {
    const session: Session = new Session()
    const weixinApi = wx ? new WeixinApi(wx, session) : undefined
    const payManager = new PayManager(session, weixinApi)

    modules = session as IExports
    modules.payPlatform = payPlatform
    modules.weixinApi = weixinApi
    modules.payManager = payManager
}


export default modules
