/**
 * 获取 url 中的参数传值
 *
 * @param {string} name
 * @returns {(string | null)}
 */
export function getQueryParameter(name: string): string | null {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === name) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null
}


/**
 * 删除 url 中的某个参数
 *
 * @param {*} url
 * @param {*} parameter
 * @returns
 */
export function removeURLParameter(parameter: string, url: string = window.location.href) {
  var urlparts = url.split('?');   
  if (urlparts.length >= 2) {

      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      for (var i = pars.length; i-- > 0;) {    
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
              pars.splice(i, 1);
          }
      }

      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

export function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent)
}