// 对应文档 weike/doc-wiki - 荔枝微课API/支付.md

// 发起微信支付 GET

export const WXPAY_URL = '/api/pay/wxpay'

export interface IWxpayResponse {
    invoice_id: number
    params: {
        nonceStr: string
        package: string
        paySign: string
        signType: string
        timestamp: string
    }
}

// 发起支付宝支付 GET
export const ALIPAY_URL = '/api/pay/alipay'

export interface IAlipayResponse {
    code: number
    data: {
        params: {
            pay_url: string
        }
    }
    invoice_id: number
    msg: string
    params: {
        pay_url: string
    }
    req_platform: string
    trade_platform: string
}

// 发起微信原生支付 GET (在 pc 页面生成二维码用来调起微信的支付的)
export const NATIVE_REPAY = '/api/pay/native_repay'

export interface INativeRepayResponse {
    code: number
    msg: string
    data: {
        code_url: string
        invoice_id: number
    }
}

// product
export type Product
    = 'reward'
    | 'redpack'
    | 'pay_lecture'
    | 'pay_channel'
    | 'relay'
    | 'relay_channel'
    | 'liveroom_vip'
    | 'gift_lecture'
    | 'gift_channel'
    | 'pay_channel_bag'
    | 'iap_recharge'
    | 'pay_channel_groupbuy'
    | 'pay_member'
    | 'pay_channel_member'

export interface IBasePayOptions {
    product: Product // 支付类型
    fee: number // 支付金额
}

export interface IPayChannelOptions extends IBasePayOptions {
    product: 'pay_channel'
    channel_id: number
    discount_id?: number
}

export interface IPayLectureOptions extends IBasePayOptions {
    product: 'pay_lecture'
    lecture_id: number
    discount_id: number
}

export interface IPayGiftChannel extends IBasePayOptions {
    product: 'gift_channel'
    channel_id: number
    gift_count: number
}

export type PayOptions = IBasePayOptions & (
    IPayChannelOptions
    | IPayGiftChannel
    | IPayLectureOptions
    )
