const ua = navigator.userAgent.toLocaleLowerCase()

export type PayPlatformType = 'weixin' | 'alipy' | 'pc'

const payTestRules: Array<[RegExp, PayPlatformType]> = [
    [/windowswechat/, 'pc'],
    [/micromessenger/, 'weixin'],
    [/alipay|weibo|qq\//, 'alipy']
]

function runTestRule<T>(rules: Array<[RegExp, T]>, defaultValue: T): T {
    let resultValue: T | null = null
    rules.forEach(([reg, value]) => {
        if (!resultValue && reg.test(ua)) {
            resultValue = value
        }
    })
    return resultValue || defaultValue
}

export const payPlatform = runTestRule(payTestRules, 'pc')
