export interface IWxConfig {
    openTagList: string[]
    debug: boolean
    appId: string
    timestamp: string
    nonceStr: string
    signature: string
    jsApiList: string[]
}

export interface IShareData {
    title?: string
    desc?: string
    link?: string
    imgUrl?: string
    success?: () => any
}

export const JSAPI_ACCESS_LIST = [
    'onMenuShareTimeline',
    'onMenuShareAppMessage',
    'updateAppMessageShareData',
    'updateTimelineShareData',
    'onMenuShareQQ',
    'onMenuShareWeibo',
    'onMenuShareQZone',
    'stopRecord',
    'startRecord',
    'onVoiceRecordEnd',
    'playVoice',
    'pauseVoice',
    'stopVoice',
    'onVoicePlayEnd',
    'uploadVoice',
    'downloadVoice',
    'chooseImage',
    'previewImage',
    'uploadImage',
    'downloadImage',
    'translateVoice',
    'getNetworkType',
    'openLocation',
    'getLocation',
    'hideOptionMenu',
    'showOptionMenu',
    'hideMenuItems',
    'showMenuItems',
    'hideAllNonBaseMenuItem',
    'showAllNonBaseMenuItem',
    'closeWindow',
    'scanQRCode',
    'chooseWXPay',
    'openProductSpecificView',
    'addCard',
    'chooseCard',
    'openCard',
    'launch3rdApp'
]

export const OPENTAGLIST = [ 'wx-open-launch-weapp' ]

export interface IWxInstance {
    ready(callback: () => any): any
    bridgeReady(callback: () => any): any

    chooseWXPay(data: any): any

    config(config: IWxConfig): any

    // 旧版本新版本
    onMenuShareAppMessage(shareData: IShareData): any

    onMenuShareTimeline(shareData: IShareData): any

    // 新版本
    updateAppMessageShareData(shareData: IShareData, callback?: () => any): any

    updateTimelineShareData(shareData: IShareData, callback?: () => any): any
}

export const wx: IWxInstance | undefined = (window as any).wx
// export const wxStatus: { ready: boolean, bridgeReady: boolean } = { ready: false, bridgeReady: false }

// if (wx) {
//     wx.ready(() => {
//         wxStatus.ready = true
//         wxStatus.bridgeReady = true
//     })
// }

// if ((window as any).WeixinJSBridge) {
//     wxStatus.bridgeReady = true
// } else {
//     document.addEventListener('WeixinJSBridgeReady', () => {
//         wxStatus.bridgeReady = true
//     }, false)
// }
